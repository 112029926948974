.selector-box {
    display: inline-flex;
    border: 3px solid $hawa-red;

    p {
        color: $hawa-red;
        margin: 0;
    }

    padding: $button-padding;
}

.selector-div {
    h2 {
        color: $hawa-light-blue;
        font-weight: bold;
        font-size: 2rem;
    }

    h1 {
        color: $hawa-dark-blue;
        font-weight: bold;
        margin-bottom: 0;
    }
}

@media (max-width: 63.9375em) {
    .selector-div {
        h2 {
            color: $hawa-light-blue;
            font-weight: bold;
            font-size: 1rem;
        }
    }
    .selector-btn-text {
        font-size: 14px;
    }
}

@media (min-width: 64em) {
    .selector-btn-text {
        font-size: 18px;
    }
}

.w-100 {
    width: 100%;
}

.mb-100 {
    margin-bottom: 100px;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.arrow-right {
    height: auto;
    max-width: 50%;
}

.arrow-div {
    display: flex;
    justify-content: flex-end;
}

.f-weight-bold {
    font-weight: bold;
}

.search-box {
    background-color: $hawa-light-blue;
    width: 36px;
    height: 36px;
    margin-left: 20px;
}

.d-inline {
    display: inline;
}

.mt-20 {
    margin-top: 20px;
}

.p-bold {
    font-weight: bold;
}