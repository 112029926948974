.comp-home-page {
    .heading--main {
        font-weight: 700;
    }

    .comp-product-type-selector {
        .inner {
            img {
                height: 251px;
            }
        }

        .text {
            font-weight: 600;

            .text--main {
                font-size: rem-calc(20);
            }
        }
    }
}