.meta-nav {
    display: none;

    @include breakpoint(large) {
        align-items: center;
        display: flex;
        height: rem-calc(36);
    }


    .meta-link {
        color: $hawa-dark-blue;
        display: block;
        font-size: rem-calc(12);
        padding: 0;
    }

    .lang-select {
        font-size: rem-calc(12);
        margin-right: rem-calc(16);
        position: relative;

        > div {
            cursor: pointer;

            > * {
                vertical-align: middle;
            }
        }
    }

    .lang-list {
        background-color: $hawa-light-gray;
        list-style: none;
        margin: 0;
        padding: rem-calc(16);
        position: absolute;
        top: 100%;
        z-index: 999;

        a {
            color: $hawa-dark-blue;

            display: block;
            padding: rem-calc(4);
        }

        .is-active {
            color: $hawa-red;
        }
    }

    .arrow-icon {
        background-image: url('/static/assets/images/arrow-down-red.svg');
        display: inline-block;
        height: rem-calc(22);

        width: rem-calc(22);
    }
}

