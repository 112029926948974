body {
  background-color: $light-gray;
  overflow-x: hidden;
}

a {
  color: $hawa-light-blue;
  text-decoration: none;
  transition: color 0.1s ease-in-out;

  &:hover {
    color: $hawa-red;
  }
}

.back-link {
  display: inline-block;
  margin-bottom: rem-calc(4);
  padding: rem-calc(4) 0;
}

p {
  line-height: 1.25;
  margin: rem-calc(18) 0;
}

h1 {
  color: $hawa-dark-blue;
  font-size: rem-calc(24);
  margin: 0 0 1.8em;

  @include breakpoint(medium) {
    font-size: rem-calc(40);
  }
}
