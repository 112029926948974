.meta-nav-mobile {
    background-color: $hawa-light-gray;

    @include breakpoint(large) {
        display: none;
    }

    .lang-select {
        @extend %list-reset;
        @include clearfix;
        padding: rem-calc(16) (map-get($grid-margin-gutters, small) / 2);

        > li {
            float: left;
            margin-right: rem-calc(12);

            &:last-child {
                margin-right: 0;
            }

            > a {
                background-color: $white;
                color: $hawa-dark-blue;
                display: block;
                padding: rem-calc(10) rem-calc(16);

                &.is-active {
                    background-color: $hawa-red;
                    color: $white;
                }
            }
        }
    }

    .meta-link {
        color: $hawa-dark-blue;
        display: block;
        padding: rem-calc(20) (map-get($grid-margin-gutters, small) / 2);
        text-transform: uppercase;
    }
}


